<template>
    <v-container>
        <v-row>
            <v-col>

                <v-card>
                    <v-card-title>
                        {{ $t('lightboxes') }}

                        <v-spacer></v-spacer>

                        <v-btn id="addButton" color="info" @click="onAdd">
                            <v-icon dark left>mdi-plus</v-icon>
                            {{ $t('add_lightbox') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field
                                id="searchField"
                                v-model="search"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                            id="dataTable"
                            :headers="headers"
                            :items="lightboxes"
                            :search="search"
                            :items-per-page="15"
                            @click:row="onClickRow"
                    ></v-data-table>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog id="dialog" v-model="dialog" persistent width="30%">
            <v-form @submit.prevent="save" ref="addLightboxForm">

                <v-card>
                    <v-card-title>
                        {{ $t('add_lightbox') }}
                    </v-card-title>

                    <v-card-text>
                        <v-text-field
                                id="lightboxNameField"
                                :label="$t('name')"
                                v-model="lightboxDetails.name"
                                :rules="lightboxRules"
                                required
                        />
                    </v-card-text>
                  <v-card-text>
                    <v-text-field
                        id="lightboxOpenField"
                        type="date"
                        :label="$t('date.available.from')"
                        v-model="lightboxDetails.date_open"

                    />
                  </v-card-text>
                  <v-card-text>
                    <v-text-field
                        id="lightboxCloseField"
                        type="date"
                        :label="$t('date.available.till')"
                        v-model="lightboxDetails.date_close"

                    />
                  </v-card-text>

                    <v-card-actions>
                        <v-btn id="cancelButton"
                               color="error"
                               @click="dialog = false"
                               v-shortkey="['esc']" @shortkey="dialog = false"
                        >
                            <v-icon dark left>mdi-cancel</v-icon>
                            {{ $t('cancel') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn id="submitButton" type="submit" color="info">
                            <v-icon dark left>mdi-send</v-icon>
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-form>
        </v-dialog>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import lightboxService from '@/services/LightboxService.js'

    export default {
        name: "Lightboxes",

        computed: {
            ...mapState(['isLoggedIn', 'user']),
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                headers: [
                    {text: this.$t('id'), value: 'id'},
                    {text: this.$t('name'), value: 'name'},
                    {text: this.$t('items'), value: 'total'},
                ],
                lightboxes: [],
                search: '',

                lightboxDetails: {
                    id: '',
                    user_id: '',
                    name: '',
                },

                dialog: false,

                lightboxRules: [
                    value => !!value || this.$t('required'),
                ],

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                lightboxService.all(this.user)
                    .then(response => {
                        this.lightboxes = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            onClickRow(rowData) {
                // Set current lightbox in store
                this.$store.dispatch('setLightbox', rowData)

                // Redirect to lightbox
                this.$router.push('/lightbox')
            },

            onAdd() {
                this.dialog = true
                this.lightboxDetails.name = ''
            },

            save() {
                if (this.$refs.addLightboxForm.validate()) {
                    this.dialog = false

                    this.lightboxDetails.user_id = this.user.id

                    lightboxService.save(this.lightboxDetails)
                        .then(response => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('data_saved')

                            // Fetch new lightbox details
                            this.lightboxDetails = response.data

                            // Set current lightbox in store
                            this.$store.dispatch('setLightbox', this.lightboxDetails)

                            this.init()
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },
        },
    }
</script>
